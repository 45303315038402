import React from "react";
import { Alert } from "@merge-api/merge-javascript-shared";

interface DeprecatedFieldAlertProps {
  modelName: string;
  fieldName: string;
  className?: string;
}

/**
 * Wrapper around merge alert to handle deprecated field messages.
 */
function DeprecatedFieldAlert({
  modelName,
  fieldName,
  className,
}: DeprecatedFieldAlertProps): JSX.Element {
  return (
    <Alert showWarningIcon color="yellow" className={className}>
      <p className="mb-0">
        The <code>{fieldName}</code> field on the <code>{modelName}</code> object is deprecated
      </p>
    </Alert>
  );
}

export default DeprecatedFieldAlert;
