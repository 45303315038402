import { APICategory } from "@merge-api/merge-javascript-shared";
import { useCallback } from "react";
import { ADDITIONAL_COMMON_MODEL_INFO, DeprecationStage } from "../assets/constants";

type UseDeprecatedCommonModelProps = {
  category: APICategory;
  commonModel?: string;
  field?: string;
};

const useDeprecatedCommonModel = ({ category, commonModel }: UseDeprecatedCommonModelProps) => {
  // helpers
  const getAdditionalCommonModelInfo = (commonModel?: string) => {
    const additionalCommonModelInfo = ADDITIONAL_COMMON_MODEL_INFO[category];

    return additionalCommonModelInfo && commonModel && commonModel in additionalCommonModelInfo
      ? additionalCommonModelInfo[commonModel]
      : { deprecationStage: undefined, deprecationMessage: undefined, fields: undefined };
  };
  const filterDeprecatedFields = (commonModel: string, fields: string[]): string[] => {
    const { fields: deprecatedFields } = getAdditionalCommonModelInfo(commonModel);

    return fields.filter(
      (field) =>
        !deprecatedFields ||
        !(field in deprecatedFields) ||
        deprecatedFields[field].deprecationStage !== DeprecationStage.SUNSET,
    );
  };

  const filterDeprecatedCommonModels = (commonModels: string[]) => {
    return commonModels.filter((commonModel) => {
      const { deprecationStage } = getAdditionalCommonModelInfo(commonModel);

      return !deprecationStage || deprecationStage !== DeprecationStage.SUNSET;
    });
  };

  // derived state
  const { deprecationStage, deprecationMessage, fields } =
    getAdditionalCommonModelInfo(commonModel);

  return {
    filterDeprecatedCommonModels,
    filterDeprecatedFields,
    deprecationStage,
    deprecationMessage,
    fields,
  };
};

export default useDeprecatedCommonModel;
