import { getModelDescription } from "@merge-api/merge-javascript-shared";
import Markdown from "markdown-to-jsx";
import type { OpenAPIV3 } from "openapi-types";
import React from "react";
import styled from "styled-components";
import OpenAPISchemaVisualizer from "./OpenAPISchemaVisualizer";
import ParameterRowAccordion from "./ParameterRowAccordion";
import ExpandableSection from "./sections/ExpandableSection";
import { getParameterRows } from "./utils/SchemaDisplayHelpers";
import DeprecatedH3 from "components/deprecated/DeprecatedH3";

const Properties = styled(DeprecatedH3)`
  && {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #121314;
    margin: 0 0 16px 0;
  }
`;

const MarkdownCode = styled.code`
  && {
    font-size: 13px;
  }
`;

interface Props {
  document: OpenAPIV3.Document;
  addEnumDescription?: boolean;
  modelDefinition: OpenAPIV3.BaseSchemaObject;
  modelName: string;
}

const NestedCommonModel = ({
  document,
  addEnumDescription = true,
  modelDefinition,
  modelName,
}: Props) => {
  const description =
    modelDefinition !== undefined ? getModelDescription(modelDefinition) : undefined;

  return description ? (
    <ExpandableSection title={`The ${modelName} object`} isNestedCommonModel={true}>
      <div>
        <Markdown
          className="markdown"
          options={{
            overrides: {
              code: {
                component: MarkdownCode,
              },
            },
          }}
        >
          {description}
        </Markdown>
        <OpenAPISchemaVisualizer
          schema={modelDefinition}
          document={document}
          style={{ margin: "24px 0" }}
        />
      </div>
      <div>
        <Properties>Properties</Properties>
        <ParameterRowAccordion expandAll>
          {getParameterRows({ document, addEnumDescription, modelName, modelDefinition })}
        </ParameterRowAccordion>
      </div>
    </ExpandableSection>
  ) : null;
};

export default NestedCommonModel;
