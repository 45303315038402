import React from "react";
import { Table } from "react-bootstrap";
import styled, { css } from "styled-components";
import { palette } from "styles/theme";

type Props = Pick<React.ComponentProps<"div">, "children" | "style" | "className">;

const TABLE_CELL_STYLE = css`
  font-size: 15px;
  line-height: 20px;
  width: 1%;
`;

const StyledTable = styled(Table)`
    table {
      will-change: transform;
      overflow-x: auto;
      overflow-y: none;
      max-width: 100%;
      table-layout: fixed;
      width: 100%;
      @media (min-width: 992px) {
        background: ${palette.white};
        box-shadow: 0 0 16px 16px ${palette.white};
        max-width: inherit;
        width: inherit;
        table-layout: auto;
      }
    }
    tr {
      border-bottom: 1px solid ${palette.border};
    }
    thead td {
      ${TABLE_CELL_STYLE};
      padding: 0 12px 16px 12px;
      font-weight: 1000;
      border-bottom: 3px solid ${palette.border};

      &:first-child {
        border-top: none;
        padding: 16px 12px 16px 0;
      }

      /* Description column is always last and needs more space */
      &:last-child {
        border-top: none;
        padding: 16px 0 16px 12px;
        min-width: 180px;
      }
    }
    tbody td {
      ${TABLE_CELL_STYLE};
      padding: 16px 12px;

      &:first-child {
        padding: 16px 12px 16px 0;
      }

      /* Description column is always last and needs more space */
      &:last-child {
        padding: 16px 0 16px 12px;
        min-width: 180px;
      }
    }
  }
`;

const FancyTable = ({ children }: Props) => <StyledTable>{children}</StyledTable>;

export default FancyTable;
